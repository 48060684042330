import get from "lodash/get";
import config from "../Config";
import request from "../utils/request";

/*
 * All API requests in the Map Report come from this piece of middleware.
 * The code within it has probably nearly outgrown the initial pattern
 * we gave it, so if a few more endpoints are added, it would be worth
 * completely refactoring this code.
 *
 * The code in this middleware kind of looks like reducer code with the switch
 * logic in it. It basically works by listening for actions and reacting to
 * certain ones by calling the makeRequest function. When that is complete,
 * we will normally then dispatch an action to the store, that will be
 * used by a reducer to update the state.
 */

export default (store) => (next) => (action) => {
  const makeRequest = request(store);
  next(action);

  /* To be displayed by notification middleware */
  const customError = (description) =>
    store.dispatch({ type: "NOTIFICATION_WARNING", description });

  const state = store.getState();

  switch (action.type) {
    /*
     * AUTH_SET should be thought of as the "official" init action. The actual
     * INIT action is fired on page load, but before a user is authenticated.
     * Of course we don't want to make any API requests unless user is
     * authenticated. So for any API call that you need to be made as soon
     * as the page loads, add into here
     */
    case "AUTH_SET":
      {
        if (action.payload.idToken && action.payload.accessToken) {
          console.log("AUTH SET, call company get & map overlay");
          makeRequest(
            {
              path: "/v1/crm/users/company/get",
              getData: (r) =>
                typeof r.results !== "undefined"
                  ? r.results[Object.keys(r.results)[0]]
                  : [],
              id: "uuid",
              params: {
                uuid: get(state.auth.idToken, "custom:forwood_uuid"),
                corporate_scope: "1",
              },
              errorMessage: "Cannot retrieve company list.",
            },
            (companies) => {
              // Include top level company structure
              companies = [{ "companies.name": companies }];
              store.dispatch({ type: "SET_COMPANY_LIST", companies });
            }
          );

          makeRequest(
            {
              path: "/mapoverlays",
              id: "overlays",
              getData: (r) => r,
              baseUrl: `${config.proxy_path_geojson_api}`,
              errorMessage: "Cannot retrieve map overlays",
              params: {
                state: "READY",
              },
            },
            (overlays) => {
              store.dispatch({
                type: "SET_OVERLAYS",
                overlays,
              });
            }
          );
        }
      }

      break;

    case "MINI_AUTH_SET":
      if (!store.getState().miniMap.lockedPin) {
        makeRequest(
          {
            path: "/hierarchy",
            id: "hierarchy",
            getData: (r) => r,
            baseUrl: `${config.proxy_path_geojson_api}`,
            errorMessage: "Cannot retrieve Go To list",
            params: {
              published: 1,
            },
          },
          (hierarchy) => {
            hierarchy =
              typeof hierarchy !== "undefined" && hierarchy.length >= 0
                ? hierarchy
                : [];

            store.dispatch({
              type: "SET_GEO_HIERARCHY",
              hierarchy,
            });
          }
        );
      }

      makeRequest(
        {
          path: "/mapoverlays",
          getData: (r) => r,
          baseUrl: `${config.proxy_path_geojson_api}`,
          errorMessage: "Cannot retrive map overlays",
          params: {
            state: "READY",
          },
        },
        (overlays) => {
          store.dispatch({
            type: "SET_OVERLAYS",
            overlays,
          });
        }
      );
      break;

    /*
     * Always re-fetch verifications and incidents if company structure
     * or date range changes. If you want another action to always trigger a
     * re-fetch, simply add a case statement here.
     *
     * Note: The localstorage middleware always triggers a SET_COMPANY action
     * after AUTH_SET, meaning these API calls are always triggered on INIT,
     * unless a user has no company in localstorage, in which case it will
     * be triggered when a company is selected in the modal
     */
    case "SET_DATE_RANGE":
    case "SET_COMPANY":
      if (!action.companyId && !state.map.companyId) return;
      if (!state.auth.accessToken || !state.auth.idToken) return;

      const params = {
        company_id: action.companyId || state.map.companyId,
        verification_date_from: state.map.date.from,
        verification_date_to: state.map.date.to,
      };

      if (action.type === "SET_COMPANY") {
        makeRequest(
          {
            path: "/v1/report/company_site",
            getData: (r) => r.results,
            id: "company_site",
            params: {
              company_structure_id: params.company_id,
            },
            errorMessage: "Cannot retrieve company list.",
          },
          (companies) => {
            store.dispatch({ type: "SET_COMPANY_SITE_IDS", companies });
          }
        );
      }

      store.dispatch({ type: "CLEAR_MAP_DATA" });

      // GEO-60: Replace static Risks with dynamic Risks by company_id and push into Redux store
      makeRequest(
        {
          path: "/v1/crm/risks/get",
          getData: (r) => (typeof r.data !== "undefined" ? r.data : []),
          id: "risks",
          params: {
            company_id: params.company_id,
          },
          errorMessage: "Cannot retrieve Critical Risks",
        },
        (data) => {
          let formattedRisks = {};
          data.forEach(function (risk) {
            formattedRisks[risk["id"]] = risk["attributes"]["name"];
          });
          store.dispatch({ type: "SET_RISKS", criticalRisks: formattedRisks });
        }
      );
      // NOTE, to be 'new client tolerant', if no risks are returned, by default we set risks to empty array & avoid application crash.
      // If this should be the case (no risks returned for current company), then the map will still function but no verifications are shown - empty map.
      // END OF GEO-60 LOGIC

      makeRequest(
        {
          path: "/v1/report/CRM_D8_incidentscclevel",
          id: "incidents",
          getData: (r) => (typeof r.data !== "undefined" ? r.data : []),
          params: { company_id: params.company_id },
          errorMessage:
            "Sorry, we couldn't load incidents. Please try reloading the page",
        },
        (incidents) => {
          store.dispatch({ type: "ADD_INCIDENTS", incidents });
        }
      );

      makeRequest(
        {
          path: "/v1/report/CRM_D1_verificationscclevel",
          id: "verifications",
          getData: (r) => r.results,
          params: {
            ...params,
            geo_location_only: 1,
          },
          errorMessage:
            "Sorry, we couldn't load verifications. Please try reloading the page",
        },
        (verifications) => {
          if (
            typeof verifications === "undefined" ||
            verifications.length <= 0
          ) {
            return customError(
              "No verification results found. Please try changing the date range."
            );
          }

          store.dispatch({ type: "ADD_VERIFICATIONS", verifications });
        }
      );

      break;

    case "SET_COMPANY_SITE_IDS":
      makeRequest(
        {
          path: "/hierarchy",
          id: "hierarchy",
          getData: (r) => r,
          baseUrl: `${config.proxy_path_geojson_api}`,
          errorMessage: "Cannot load GeoAreas",
        },

        (hierarchy) => {
          // Get Company Sites
          let companySitesHierarchy = store
            .getState()
            .map.companySites.map((site) => parseInt(site.term_id));

          // Set site hierarchy to include only relevant sites
          hierarchy =
            typeof hierarchy !== "undefined" && hierarchy.length > 0
              ? hierarchy
                  .map((site) => {
                    if (companySitesHierarchy.includes(site.site_id)) {
                      return site;
                    }

                    return undefined;
                  })
                  .filter((item) => item)
              : [];

          store.dispatch({
            type: "SET_GEO_AREA_HIERARCHY",
            hierarchy,
          });
        }
      );

      break;

    default:
      return;
  }
};
