export default {
  proxy_path: "https://bn7odtqena.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://prp25r6lgg.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyB9O0BHfLu42XEe_50uDzubnJzPHFI0m6s",
  main_company_term_id: "9593",
  crm_base_url: "staging.evolution.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.evolution.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.staging.evolution.forwoodsafety.com",
    APP_CLIENT_ID: "1lj629a3gji4e4akg8dn2qpeo5",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://1ah1yl1a99.execute-api.us-west-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.staging.evolution.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
